(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/poker-react/components/poy-ranking-list/assets/javascripts/utils.js') >= 0) return;  svs.modules.push('/modules/poker-react/components/poy-ranking-list/assets/javascripts/utils.js');
"use strict";

const {
  jupiter,
  log
} = svs.core;
const logger = log.getLogger('module:poker_react:components:poy_ranking_list');
const {
  year: rankingYear
} = svs.poker_react.data.poyCurrentRankingConfig;
const QuarterFilters = [{
  label: 'Kvartal 1',
  value: '01'
}, {
  label: 'Kvartal 2',
  value: '04'
}, {
  label: 'Kvartal 3',
  value: '07'
}, {
  label: 'Kvartal 4',
  value: '10'
}];
const Tags = [{
  label: 'POY-Ranking',
  value: 'POY'
}, {
  label: 'Mini-Ranking',
  value: 'MINI'
}];
const normalizePlayers = playerList => playerList.map(player => ({
  value: player.points,
  name: player.nickname,
  rankingPos: player.rankingPos
}));
const fetchRankings = async _ref => {
  let {
    period,
    searchString,
    tag
  } = _ref;
  try {
    const params = new URLSearchParams();
    const rankingPeriod = period === 'year' ? period : 'quarter';
    params.append('period', encodeURIComponent(rankingPeriod));
    if (searchString !== null && searchString !== void 0 && searchString.length) {
      params.append('qs', encodeURIComponent(searchString));
    }
    params.append('tag', tag);
    const rankingMonth = period === 'year' ? '01' : period;
    const dateString = dateFns.format(new Date("".concat(rankingYear, "-").concat(rankingMonth, "-01 00:00")), 'yyyy-MM-dd HH:mm');
    params.append('time', dateString);
    const {
      response
    } = await jupiter.callAsync({
      method: 'GET',
      path: "/poker/1/ranking?".concat(params.toString())
    });
    return {
      lastUpdated: response.result.lastUpdated || '-',
      players: normalizePlayers(response.result.players || []),
      isNameSearch: response.result.isNameSearch || false,
      totalPlayerCount: response.result.totalPlayerCount || 0,
      error: null
    };
  } catch (error) {
    logger.error("Error fetching poker ranking: ".concat(error));
    return {
      lastUpdated: '-',
      players: [],
      isNameSearch: false,
      totalPlayerCount: 0,
      error
    };
  }
};
const getPeriodFilters = () => {
  const currentQuarter = dateFns.getQuarter(new Date(rankingYear));
  return [{
    label: 'År',
    value: 'year'
  }, ...QuarterFilters.slice(0, currentQuarter)];
};
setGlobal('svs.poker_react.components.poyRankingList.common', {
  fetchRankings,
  normalizePlayers,
  Periods: getPeriodFilters(),
  Tags
});

 })(window);